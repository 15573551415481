<template>
  <header>
    <a href="https://www.ilpost.it" target="_blank"><img src="./assets/logo.svg" /></a>
  </header>
  <router-view/>
</template>
<style lang="scss">
body{
    background-color:#f7f7f6;
    margin:0px;
    header{
      text-align: center;
        border-bottom:1px solid #E5E5E5;
        height: 45px;
        margin-bottom:20px;
        background-color:white;
        a{
          height: 45px;
          display: inline-block;
          vertical-align: middle;
        }
        img{
          height: 29px;
          margin-top:6px;
        }
    }
}
</style>
