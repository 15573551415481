/* eslint-disable */
import axios from 'axios'
import type {App} from 'vue'

interface AxiosOptions {
}

export default {
    install: (app: App, options: AxiosOptions) => {
        app.config.globalProperties.$axios = axios.create()
    }
}